/**
 * Navigation Menu component using Bootstrap components
 * @author FacundoCerezo
 *
 */

import React, { useState }  from "react"
import { Nav, Tooltip, OverlayTrigger } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"


const NavigationMenu = ({ children }) => {
  const [tooltip, setTooltip] = useState("Copiar email");

  const renderTooltip = props => (
    <Tooltip id="email-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )

  return (
    <Nav className="text-uppercase ml-auto">
      <Nav.Item>
        <AnchorLink className="nav-link" to="/#quienes-somos">
          Sobre nosotros
        </AnchorLink>
      </Nav.Item>
      <Nav.Item>
        <AnchorLink className="nav-link" to="/#networking">
          Networking
        </AnchorLink>
      </Nav.Item>
      <Nav.Item>
        <AnchorLink className="nav-link" to="/#webdev">
          Webdev
        </AnchorLink>
      </Nav.Item>
      <Nav.Item>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 50, hide: 100 }}
          overlay={renderTooltip}
        >
          <Nav.Link onClick={() => {navigator.clipboard.writeText("info@chaltenet.com");setTooltip(" ¡Copiado! ")}}>info@chaltenet.com</Nav.Link>
        </OverlayTrigger>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="https://wa.me/5492966720720">WhatsApp</Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export default NavigationMenu;
