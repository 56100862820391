import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
<footer className="chaltenet-footer text-white text-center">
© {new Date().getFullYear()}, ChalteNet - El Chaltén, Santa Cruz, Argentina
{/* {` `}
<a href="https://www.gatsbyjs.org">Gatsby</a> */}
</footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
